require('./bootstrap');

require('alpinejs');

const hamburger = document.querySelector('.hamburger-btn');

if (hamburger) {
    hamburger.addEventListener('click', function (e) {
        openSidebar(this)
            .then(() => {
                const backdrop = document.querySelector('.backdrop');
                backdrop.addEventListener('click', function (e) {
                    openSidebar(hamburger)
                })
            })
    })
}

async function openSidebar(button) {
    const div = document.createElement('div');
    const sidebar = document.querySelector('aside.sidebar');
    div.classList.add('backdrop');

    if (sidebar.classList.contains('open')) {
        document.querySelector('.backdrop').remove()
    } else {
        document.body.appendChild(div)
    }

    button.classList.toggle('toggled');
    sidebar.classList.toggle('open');
}

$('aside.sidebar .menu-link').click(function () {
    const item = $(this);

    item.parents('.menu-item').toggleClass('show');
    item.parents('.menu-item').siblings().removeClass('show');
    item.parents('.menu-item').children('.menu-link').toggleClass('active');
    item.parents('.menu-item').siblings().children('.menu-link').removeClass('active');

})

$('aside.sidebar .sub-menu a').click(function () {
    const subMenuButton = $(this)
    const siblinsChildButon = subMenuButton.parents('.menu').children('.menu-item').siblings().find('.menu-item-child').find('a');

    siblinsChildButon.removeClass('active')
    subMenuButton.addClass('active');
})

function filterFunction(el) {
    const childrens = $(el).parents('.dropdown-menu').find('.dropdown-result').children();
    let filter = $(el).val().toUpperCase();
    for (let i = 0; i < childrens.length; i++) {
        let txtValue = childrens[i].textContent || childrens[i].innerText;
        if (txtValue.toUpperCase().indexOf(filter) > -1) {
            childrens[i].style.display = "";
        } else {
            childrens[i].style.display = "none";
        }
    }
}

const dropdownSearchable = document.querySelectorAll('#dropdown-search-input');
dropdownSearchable.forEach(dropdown => {
    dropdown.addEventListener('keyup', function (e) {
        filterFunction(e.target)
    })
});

$('[data-action="show-floating-search"]').click(function () {
    $('.float-search').toggleClass('show')
})
